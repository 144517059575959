import { SerializedError } from "@reduxjs/toolkit";
import { useCallback, useState } from "react";

import { useAppDispatch, useAppSelector } from "../../../hooks";
import actions from "../actions";
import { IProgramTopWorst } from "../slices/programsTopWorst";

export interface IProgramsTopWorst {
  fetching: boolean;
  fetched: boolean;
  items: IProgramTopWorst[];
  load(): void;
  error: SerializedError | null;
}

export const useProgramTopWorst = (): IProgramsTopWorst => {
  const dispatch = useAppDispatch();
  const {
    fetching,
    items: programsTopWorst = [],
    fetched,
  } = useAppSelector((state) => state.gosprogram.programsTopWorst);
  const [error, setError] = useState<SerializedError | null>(null);

  const load = useCallback(() => {
    if (!fetching) {
      dispatch(actions.programTopWorst.get()).then((action) => {
        if (actions.programTopWorst.get.rejected.match(action)) {
          setError(action.error);
        }
        return action;
      });
    }
  }, [dispatch, fetching]);

  return {
    fetching,
    fetched,
    items: programsTopWorst,
    load,
    error,
  };
};
