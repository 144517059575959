import { Grid, useMediaQuery, useTheme } from "@mui/material";
import { useMemo } from "react";
import Counter from "SRC/components/ProgramsCounter/ProgramsCounter";
import Select from "SRC/components/Select/Select";

interface IGovProgramsFiltersProps {
  generalInfoVicePremiersFilter: string;
  setGeneralInfoVicePremiersFilter: (value: string) => void;
  generalInfoFoivFilter: string;
  setGeneralInfoFoivFilter: (value: string) => void;
  generalInfoVicePremiersFilterValues: (string | number | undefined)[];
  generalInfoFoivFilterValues: (string | undefined)[];
  tableDataLength: number;
}

export const GeneralInfoTabFilters = ({
  generalInfoVicePremiersFilter,
  setGeneralInfoVicePremiersFilter,
  generalInfoFoivFilter,
  setGeneralInfoFoivFilter,
  generalInfoVicePremiersFilterValues,
  generalInfoFoivFilterValues,
  tableDataLength,
}: IGovProgramsFiltersProps) => {
  const theme = useTheme();
  const match = useMediaQuery(theme.breakpoints.up("lg"));

  const vicePremiersSelectOptions = useMemo(
    () =>
      generalInfoVicePremiersFilterValues.map((label) => ({
        value: label,
        label,
      })),
    [generalInfoVicePremiersFilterValues]
  );

  const foivSelectOptions = useMemo(
    () =>
      generalInfoFoivFilterValues.map((label) => ({
        value: label,
        label,
      })),
    [generalInfoFoivFilterValues]
  );

  return (
    <Grid
      container
      justifyContent={match ? "space-between" : "flex-start"}
      sx={{
        marginBottom: match ? "1rem" : "0rem",
        maxWidth: match ? "100%" : "50%",
      }}
    >
      <Grid item sm={4}>
        <Select
          options={vicePremiersSelectOptions}
          value={generalInfoVicePremiersFilter}
          onChange={(value: string) => setGeneralInfoVicePremiersFilter(value)}
          label="Вице-премьеры"
        />
      </Grid>
      <Grid item sm={4} sx={{ pl: 10 }}>
        <Select
          options={foivSelectOptions}
          value={generalInfoFoivFilter}
          onChange={(value: string) => setGeneralInfoFoivFilter(value)}
          label="Министерства"
        />
      </Grid>
      <Grid
        item
        sm={2}
        sx={{
          display: "flex",
          height: "2.5rem",
          alignSelf: "flex-end",
          marginLeft: "1rem",
        }}
      >
        <Counter tableDataLength={tableDataLength} />
      </Grid>
    </Grid>
  );
};
