import { combineReducers } from "@reduxjs/toolkit";

import auth, { IAuthReducer } from "./slices/auth/reducer";
import gosprogram, { IGosprogramReducer } from "./slices/gosprogram/reducer";
import main, { IMainReducer } from "./slices/main/reducer";

export interface IState {
  auth: IAuthReducer;
  programs: IMainReducer;
  gosprogram: IGosprogramReducer;
}

const reducer = combineReducers({
  auth,
  main,
  gosprogram,
});

export default reducer;
